import React from "react";

import EditOperatingDetailsView, { processServerErrors } from "./view";
import { EditFacilityDialog } from "@pages/DeepDivePanel/EditFacilityDialog";

const EditOperatingDetails: React.FC = () => {
  return (
    <EditFacilityDialog
      name="EditOperatingDetails"
      title="Operating details"
      processErrors={processServerErrors}
    >
      {(view, setView) => (
        <EditOperatingDetailsView
          facilityView={view}
          setFacilityView={setView}
        />
      )}
    </EditFacilityDialog>
  );
};

export default EditOperatingDetails;
